import NextHeadSeo from 'next-head-seo'
import '@/styles/global.css'

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page)
  return (
    <>
      <NextHeadSeo
        title="証頡佳頴 J&E Wedding 4/9"
        canonical={process.env.NEXT_PUBLIC_SITE_URL}
        description="Hi, 各位親愛的家人與朋友們，我們將在 2022.4.9 (六) 結婚囉！🥳"
        og={{
          type: 'website',
          siteName: '証頡佳頴 J&E Wedding 4/9',
        }}
        customMetaTags={[
          {
            property: 'og:image',
            content: process.env.NEXT_PUBLIC_SITE_URL + '/og-image-2.png',
          },
          {
            property: 'og:image:width',
            content: '1200',
          },
          {
            property: 'og:image:height',
            content: '630',
          },
          {
            property: 'og:image',
            content: process.env.NEXT_PUBLIC_SITE_URL + '/og-image-1.png',
          },
          {
            property: 'og:image:width',
            content: '1200',
          },
          {
            property: 'og:image:height',
            content: '1200',
          },
        ]}
      />
      {getLayout(<Component {...pageProps} />)}
    </>
  )
}

export default MyApp
